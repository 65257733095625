import React, { useState, useRef } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavRoad from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"
import SmallImg from "../../../components/Image/SmallImg"

import {
  Title,
  Select,
  Input,
  Button,
  Field,
  Control,
  Columns,
  Column,
  StaticButton,
} from "../../../StyleComponents/styles"
import { FlexDiv } from "../../../StyleComponents/pagesStyle"

const navRoadArray = [
  { name: "Calculator", link: "/calculator/" },
  {
    name: "Conical Calculator",
    link: "/conical-frustum-volume-and-area-calculator/",
  },
]
const seeAlsoArray = [
  "/capsule-volume-and-area-calculator",
  "/cone-volume-and-area-calculator",
  "/cube-volume-and-area-calculator",
  "/cylinder-volume-and-area-calculator",
  "/ellipsoid-volume-and-area-calculator",
  "/percentage-calculator",
  "/rectangular-volume-and-area-calculator",
  "/sphere-volume-and-area-calculator",
  "/square-pyramid-volume-and-area-calculator",
]

function ConicalFrustumCalculatorVolumeArea(props) {
  let [result, setresult] = useState("")
  let radius = useRef("")
  let Bottomradius = useRef("")
  let height = useRef("")
  let unitSelector = useRef("")

  function calcVolume(e) {
    let radiusValue = radius.current.value
    let heightValue = height.current.value
    let botRadius = Bottomradius.current.value
    let unit = unitSelector.current.value

    if (radiusValue === "" || isNaN(radiusValue)) {
      setresult(<strong>Please Enter Radius Value </strong>)
    } else if (heightValue === "" || isNaN(heightValue)) {
      setresult(<strong>Please Enter Height Value</strong>)
    } else if (botRadius === "" || isNaN(botRadius)) {
      setresult(<strong>Please Enter Bottom Radius Value</strong>)
    } else {
      radiusValue = Number(radiusValue)
      botRadius = Number(botRadius)
      heightValue = Number(heightValue)

      let volume =
        (1 / 3) *
        Math.PI *
        heightValue *
        (Math.pow(radiusValue, 2) +
          Math.pow(botRadius, 2) +
          radiusValue * botRadius)

      setresult(
        <span>
          Volume is : {volume.toFixed(2) + unit}
          <sup>3</sup>{" "}
        </span>
      )
    }
  }
  function calcArea(e) {
    let radiusValue = radius.current.value
    let botRadius = Bottomradius.current.value
    let heightValue = height.current.value
    let unit = unitSelector.current.value

    if (radiusValue === "" || isNaN(radiusValue)) {
      setresult(<strong>Please Enter Radius Value </strong>)
    } else if (heightValue === "" || isNaN(heightValue)) {
      setresult(<strong>Please Enter Height Value</strong>)
    } else if (botRadius === "" || isNaN(botRadius)) {
      setresult(<strong>Please Enter Bottom Radius Value</strong>)
    } else {
      radiusValue = Number(radiusValue)
      heightValue = Number(heightValue)
      botRadius = Number(botRadius)

      let slant = Math.sqrt(
        Math.pow(radiusValue - botRadius, 2) + Math.pow(heightValue, 2)
      )
      let surfaceArea =
        Math.PI *
        (Math.pow(radiusValue, 2) +
          Math.pow(botRadius, 2) +
          (radiusValue + botRadius) * slant)

      let basearea = Math.PI * Math.pow(botRadius, 2)

      let topsurfaceArea = Math.PI * Math.pow(radiusValue, 2)

      setresult(
        <span>
          Total surface area : {surfaceArea.toFixed(2) + unit}
          <sup>2</sup>
          <br />
          Top Surface Area : {topsurfaceArea.toFixed(2) + unit}
          <sup>2</sup> <br />
          Base Surface Area : {basearea.toFixed(2) + unit}
          <sup>2</sup>
          <br />
          Slant : {slant.toFixed(2) + unit}
        </span>
      )
    }
  }

  return (
    <Layout location={props.location}>
      <SEO
        title="Conical Frustum Volume and Area Calculator"
        description="Conical Frustum Calculator Volume and Area, Simple Fast calculation, calculate volume, total Surface area, base area, top area, and slant height."
        keywords={[
          "Conical Frustum Calculator,Conical Volume calculation, calculate Total Area, Conical Base Area, Conical Top Area, Conical Slant height, Conical Calculator, calculate conical volume, conical area, conical slant hright, conical top area, conical base area, conical total area, coniacl volume, how to calculate volume, how to calculate conical area, calc area conical, conical salnt height. how to calculate conical?",
        ]}
      />
      <NavRoad listPages={navRoadArray} />
      <div className="container">
        <Title>Conical Frustum Volume and Area Calculator</Title>
        <Columns>
          <Column>
            <Field>
              <Control>
                <StaticButton>Top Radius</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  className="borderedRight"
                  type="number"
                  ref={radius}
                />
              </Control>
            </Field>

            <Field>
              <Control>
                <StaticButton>Bottom Radius</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  className="borderedRight"
                  type="number"
                  ref={Bottomradius}
                />
              </Control>
            </Field>

            <Field>
              <Control>
                <StaticButton>Height</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  className="borderedRight"
                  type="number"
                  ref={height}
                />
              </Control>
            </Field>

            <Field>
              <Control>
                <StaticButton>Select Unit</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Select>
                  <select
                    aria-label="unitSelector"
                    className="staticSelect"
                    ref={unitSelector}
                  >
                    <option value="m">m</option>
                    <option value="cm">cm</option>
                    <option value="mm">mm</option>
                    <option value="km">km</option>
                    <option value="dm">dm</option>
                  </select>
                </Select>
              </Control>
            </Field>
            <Button
              borderColor="#1da1f2"
              color="#1da1f2"
              hoverColor="white"
              hoverBorderColor="#1da1f2"
              hoverBackgroundColor="#1da1f2"
              onClick={calcVolume}
            >
              Calculate Volume
            </Button>
            <Button
              borderColor="#1da1f2"
              color="white"
              backgroundColor="#1da1f2"
              hoverColor="#1da1f2"
              hoverBorderColor="#1da1f2"
              onClick={calcArea}
            >
              Calculate area
            </Button>

            <p className="h5 mt-3">{result}</p>
          </Column>
          <Column>
            <FlexDiv maxWidth="250px" margin="auto">
              <SmallImg
                filename="conical.png"
                alt="Conical Frustum calculator radius and height"
              />
            </FlexDiv>
          </Column>
        </Columns>
        <br />
        <h3>How To Calculate Conical Frustum Volume?</h3>
        <p>
          <strong>
            Conical Frustum Volume = 1/3 * π * height * (top radius
            <sup>2</sup>+ top radius * bottom radius + bottom radius
            <sup>2</sup>) ,
            <br />
            ex : top radius = 15, bottom radius = 20, height = 30 . Volume = 1/3
            * π * 30 * (15
            <sup>2</sup> + 15 * 20 + 20<sup>2</sup>)= 29059.732 m<sup>3</sup>{" "}
          </strong>
          .
        </p>
        <br />
        <h3>Calculate Conical Frustum Area?</h3>
        <p>
          Area =
          <strong>
            &pi; * [ Top Radius<sup>2</sup> + bottom Radius<sup>2</sup>+ (Top
            Radius + Bottom Radius) * &radic;
            <span style={{ textDecoration: "overline" }}>
              (Top Radius - Bottom Radius)<sup>2</sup> + height
              <sup>2</sup>)
            </span>
            ] .
          </strong>
          <br />
          Top area =
          <strong>
            &pi; * Top Radius<sup>2</sup>.
          </strong>
          <br />
          Base area =
          <strong>
            &pi; * Bottom Radius<sup>2</sup>.
          </strong>
        </p>
        Slant height =
        <strong>
          &radic;
          <span style={{ textDecoration: "overline" }}>
            (Top Radius - Bottom Radius)<sup>2</sup> + height<sup>2</sup>)
          </span>
          .
        </strong>
        <br />
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default ConicalFrustumCalculatorVolumeArea
